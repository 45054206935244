<template>
 <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Update Profile</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <form onsubmit="return false" novalidate="novalidate">
                        <div class="row gy-2 px-2">
                          <div class="col-12 col-md-12">
                            <label class="form-label" for="name">Name</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              class="form-control"
                              placeholder="name"
                              v-model="formData.name"
                            />
                          </div>
                          <div class="col-12 col-md-12">
                            <label class="form-label" for="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              placeholder="email"
                              v-model="formData.email"
                            />
                          </div>
                          <div class="col-12 col-md-12">
                            <label class="form-label" for="phone">Phone</label>
                            <input
                              type="text"
                              id="phone"
                              class="form-control"
                              placeholder="phone"
                              v-model="formData.phone"
                            />
                          </div>
                          <div class="col-12 col-md-12">
                            <label class="form-label" for="phone">Photo</label>
                            <input
                              type="file"
                              id="photo"
                              class="form-control"
                              @change="photoUpload"
                            />
                          </div>
                          <div class="col-12 col-md-12">
                            <label class="form-label" for="cover_photo">Cover Photo</label>
                            <input
                              type="file"
                              id="cover_photo"
                              class="form-control"
                              @change="coverPhotoUpload"
                            />
                          </div>
                        </div>
                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button @click="$emit('onClickSubmitProfile')" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditProfileModal',
    props: ['formData', 'loading'],
    data: () => ({
      isOpen: false
    }), 
    methods: {
      toggleModal () {
          this.isOpen = !this.isOpen
      },
      photoUpload(event) {
          this.formData.photo = event.target.files[0]
      },
      coverPhotoUpload(event) {
          this.formData.cover_photo = event.target.files[0]
      },
    }
}
</script>
