<template>
  <div @click="fetchCompanyModuleByFirstMenu(company.id)" class="card cursor-pointer ecommerce-card">
    <div class="d-flex p-1">
      <img class="companyLogo height-100 width-100"
           :src="company.logo"
      />
      <div class="px-2">
        <h3> {{ company.name }}</h3>
        <h5>{{ company.industry }} </h5>
        <h5>{{ company.address }}</h5>
      </div>
    </div>
  </div>
</template>

<script setup>
import handleSidebar from "@/services/modules/sidebar";
const {fetchCompanyModuleByFirstMenu} = handleSidebar()

defineProps({
  company: Object,
})
</script>
